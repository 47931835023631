import { extendTheme } from '@chakra-ui/react'

const components = {
  Link: {
    baseStyle: (props: unknown) => ({
      color: 'white',
      _hover: {
        color: 'white',
      },
      _focus: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    }),
  },
  Button: {
    baseStyle: (props: unknown) => ({
      _focus: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    }),
  },
  Checkbox: {
    baseStyle: (props: unknown) => ({
      _focus: {
        boxShadow: 'none',
      },
    }),
  },
}
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  components,
  config,
})

export default theme
