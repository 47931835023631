import { Router } from '@reach/router'

import SecureLayout from 'components/SecureLayout'
import AboutUs from 'pages/AboutUs'
import Home from 'pages/Home'
import Wedding from 'pages/Wedding'

const Routes = () => (
  <Router>
    <Home path="/boda-civil" fecha="julio 9, 2022" />
    <Home path="/boda-religiosa" fecha="enero 14, 2023" />
    <Wedding path="/wedding" />
    <AboutUs path="/us" />
    <Home path="/" fecha="julio 9, 2022" />
    <SecureLayout path="/app" />
  </Router>
)

export default Routes
