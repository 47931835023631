import { Container, Flex, Image } from '@chakra-ui/react'
import { Link } from '@reach/router'
// import { useIntl } from 'react-intl'

interface Props {}

const logoUrl = process.env.PUBLIC_URL + '/img/logo-white.png'

const Navbar = (props: Props) => {
  // const intl = useIntl()
  // const fontSize = { base: '12px', md: '20px' }
  const gap = { base: 5, md: 10, lg: 20 }

  return (
    <Container display="flex" maxW="container.xl" justifyContent="center" color="white">
      <Flex textTransform="uppercase" gap={gap} alignItems="center" display={{ base: 'none', md: 'flex' }}>
        {/* <Text fontFamily="GalleryModern" letterSpacing={3} fontSize={fontSize} fontWeight="bold">
          {intl.formatMessage({ id: 'menu.the_itinerary' })}
        </Text> */}
      </Flex>
      <Link to="/">
        <Image
          boxSize={{ base: '50px', md: '100px' }}
          objectFit="scale-down"
          src={logoUrl}
          alt="logo"
          marginX={gap}
        />
      </Link>
      <Flex
        textTransform="uppercase"
        gap={gap}
        alignItems="center"
        justifyContent="center"
        display={{ base: 'none', md: 'flex' }}
      >
        {/* <Text fontFamily="GalleryModern" letterSpacing={3} fontSize={fontSize} fontWeight="bold">
          {intl.formatMessage({ id: 'menu.the_wedding' })}
        </Text> */}
      </Flex>
    </Container>
  )
}

export default Navbar
