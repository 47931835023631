import {
  Button,
  Container,
  PinInput,
  HStack,
  PinInputField,
  Heading,
  Input,
  Stack,
  useToast,
  Flex,
  Spacer,
  Image,
} from '@chakra-ui/react'
import { Link, RouteComponentProps } from '@reach/router'
import { useEffect, useRef, useState } from 'react'

import { useAuth } from 'config/AuthProvider'

interface Props extends RouteComponentProps {
  //
}

const logoUrl = process.env.PUBLIC_URL + '/img/logo-white.png'

const LENGTH = 6

const LoginPage = (props: Props) => {
  const { loginWithGoogle, loginWithSmsSendCode, loginWithSmsCheckCode } = useAuth()
  const [phoneAuth, setPhoneAuth] = useState<'ask-number' | 'fill-code' | null>(null)
  const [phone, setPhone] = useState<string>('+502 ')
  const toast = useToast()

  const [pin, setPin] = useState('')
  const timerRef = useRef<number>(0)
  const codeRef = useRef<Array<string>>([])
  const isWatchSMS = useRef<Boolean>(true)

  const onPinChange = (e: string) => {
    if (!isWatchSMS.current) {
      setPin(e)

      return
    }
    // Compatible other browser input, like WeChat or Chrome.
    if (e.length === LENGTH) {
      setPin(e)
      isWatchSMS.current = false

      return
    }

    // Check is Safari aotucomplete or not.
    clearTimeout(timerRef.current)
    // @ts-ignore
    timerRef.current = setTimeout(() => {
      setPin(e)
      isWatchSMS.current = false
    }, 100)
    // Compatible safari sms autocomplete.
    codeRef.current.push(e)
    if (codeRef.current.length === LENGTH) {
      clearTimeout(timerRef.current)
      setPin(codeRef.current.join(''))
      codeRef.current = []
      isWatchSMS.current = false
    }
  }

  const onPinComplete = async (pin: string) => {
    toast({ title: 'authenticating...' })
    try {
      await loginWithSmsCheckCode(pin)
      toast({ title: 'success', status: 'success' })
    } catch {
      toast({ title: 'authentication failed', status: 'error' })
    }
  }

  useEffect(() => {
    if (pin.length === LENGTH) onPinComplete(pin)
    // eslint-disable-next-line
  }, [pin])

  const handleLogin = async () => {
    try {
      const r = await loginWithGoogle()
      console.log(r)
    } catch (err) {
      toast({
        title: 'Error to login',
        // @ts-ignore
        description: err.message,
        status: 'error',
      })
    }
  }

  const onClickSms = () => void setPhoneAuth('ask-number')
  const onClickSendCode: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    await loginWithSmsSendCode(phone)
    setPhoneAuth('fill-code')
  }
  const onPhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => void setPhone(e.target.value)

  return (
    <>
      <Flex flexDir="column">
        <Flex bg="#565347" zIndex="2" color="white" boxShadow="md" width="100vw" height="48px">
          <Container maxW="container.lg" display="flex" justifyContent="center" py={2}>
            <Link to="/wedding">
              <Image height="30px" src={logoUrl} />
            </Link>
            <Spacer />
          </Container>
        </Flex>
      </Flex>
      <Container display="flex" flexDir="column" gap={5} mt={5}>
        <Heading>Login</Heading>
        {!phoneAuth && (
          <>
            <Button onClick={handleLogin}>Login with Google</Button>
            <Button onClick={onClickSms}>Login with sms</Button>
          </>
        )}
        {!!phoneAuth && (
          <form onSubmit={onClickSendCode}>
            <Stack>
              <label>Enter phone number</label>
              <Input disabled={phoneAuth === 'fill-code'} value={phone} onChange={onPhoneChange} type="tel" />
              <Button type="submit" disabled={phoneAuth === 'fill-code'} id="sign-in-button">
                Send Code
              </Button>
            </Stack>
          </form>
        )}
        {phoneAuth === 'fill-code' && (
          <Stack>
            <label>Enter code</label>
            <HStack justifyContent="center">
              <PinInput value={pin} onChange={onPinChange}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Stack>
        )}
      </Container>
    </>
  )
}

export default LoginPage
