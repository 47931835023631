import { Container, Flex, Image, Text } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { motion } from 'framer-motion'
import { Toaster } from 'react-hot-toast'
import { useIntl } from 'react-intl'

import BeOurGuestAndInfo from 'components/BeOurGuestAndInfo'
import LanguageTopbar from 'components/LanguageTopbar'
import Navbar from 'components/Navbar'
// import QuickRsvp from 'components/QuickRSVP'

interface Props extends RouteComponentProps {}

const imgUrl = process.env.PUBLIC_URL + '/img/us/c.jpg'
const aleYJagUrl = process.env.PUBLIC_URL + '/img/ale-jag.png'
const logoUrl = process.env.PUBLIC_URL + '/img/logo.png'

const WeddingPage = (props: Props) => {
  const intl = useIntl()
  const height = { base: '350px', md: '650px' }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <Toaster position="top-center" reverseOrder={true} />
      <Flex flexDirection="column">
        <Flex
          backgroundColor="#31302c"
          backgroundImage={`url('${imgUrl}')`}
          backgroundPosition={{ base: '0% 70%', md: '50% 70%' }}
          backgroundSize={{ base: 'cover' }}
          transition="all 1s"
          backgroundRepeat="no-repeat"
          flexDir="column"
          height={height}
        >
          <Flex flexDir="column" bg="blackAlpha.700" height={height}>
            <LanguageTopbar />
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
              <Navbar />
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
              <Container maxW="container.xl" pt={{ base: '20px', md: '150px' }}>
                <Text
                  fontSize={{ base: '20px', md: '30px' }}
                  fontFamily="GalleryModern"
                  letterSpacing={5}
                  color="white"
                >
                  {intl.formatMessage({ id: 'main.we_are_getting_married' })}
                </Text>
                <Image height={{ base: '100px', md: '160px' }} src={aleYJagUrl} />
              </Container>
            </motion.div>
          </Flex>
        </Flex>
        <Flex bg="white">
          <Container
            maxW="container.xl"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            gap={10}
          >
            <BeOurGuestAndInfo />
          </Container>
        </Flex>
        <Flex position="relative" flexDir="column">
          <Flex bg="#eaeae7" padding={5}>
            <Container maxW="container.xl">
              <Flex
                gap={10}
                flexDir={{ base: 'column', lg: 'row' }}
                justifyContent={{ base: 'flex-start', lg: 'center' }}
              >
                <Image
                  border="10px solid #ddd5ba"
                  height={{ base: '240px', lg: '300px' }}
                  boxSize="scale-down"
                  boxShadow="lg"
                  src="https://image-tc.galaxy.tf/wijpeg-6sobkul6vrrwsh132z5knszo7/file.jpg?width=1200"
                />
                <Image
                  border="10px solid #ddd5ba"
                  boxShadow="lg"
                  display={{ base: 'none', lg: 'block' }}
                  height={{ base: '240px', lg: '300px' }}
                  boxSize="scale-down"
                  src="https://image-tc.galaxy.tf/wijpeg-dgucfb21pms9vidsaoxn0pva7/file.jpg?width=1200"
                />
              </Flex>
              <Flex alignItems="center" borderTop="1px solid #ceccc2" mt={5} pt={3}>
                <Image
                  boxSize={{ base: '50px', md: '50px' }}
                  objectFit="scale-down"
                  src={logoUrl}
                  alt="logo"
                />
                <Flex flexDir="column" ml={2}>
                  <Text fontFamily="GalleryModern" display="flex">
                    {intl.formatMessage({ id: 'footer.made_with_love' })}
                    <span style={{ margin: '0 12px 0 4px' }}>❤️</span>
                  </Text>
                  <Text fontFamily="GalleryModern">{intl.formatMessage({ id: 'footer.by_aj' })}</Text>
                </Flex>
              </Flex>
            </Container>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  )
}

export default WeddingPage
