import { ChakraProvider } from '@chakra-ui/react'
import { queryClient } from 'hooks/reactquery'
import { QueryClientProvider } from 'react-query'

import LanguageProvider from 'components/LanguageProvider'
import { AuthProvider } from 'config/AuthProvider'
import Fonts from 'config/Fonts'
import Routes from 'config/Routes'
import theme from 'config/theme'

const Root = () => (
  <>
    <LanguageProvider>
      <QueryClientProvider client={queryClient}>
        <Fonts />
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </LanguageProvider>
  </>
)

export default Root
