import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Santa-Fe-Spring';
        src: url('${process.env.PUBLIC_URL}/fonts/Santa Fe Spring TTF.ttf');
      }

      /* latin */
      @font-face {
        font-family: 'GalleryModern';
        src: url('${process.env.PUBLIC_URL}/fonts/GalleryModern.otf');
      }
      `}
  />
)

export default Fonts
