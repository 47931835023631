import { Flex, Image } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { useState } from 'react'
// @ts-ignore
import { useInterval } from 'react-recipes'

const imgs = ['a', 'b', 'c', 'd', 'e', 'f'].map((img) => process.env.PUBLIC_URL + 'img/us/' + img + '.jpg')

const getRandom = (max: number): number => {
  return Math.floor(Math.random() * (max - 0 + 1) + 0)
}

const Foto = ({ row, col }: { row: number; col: number }) => {
  const [imgIndex, setIndex] = useState(getRandom(imgs.length - 1))
  const img = imgs[imgIndex]
  const id = Number(`${row}${col}`)

  useInterval(() => {
    setIndex(getRandom(imgs.length - 1))
  }, 1000 * (id + 1))

  return <Image boxSize="200px" objectFit="cover" src={img} />
}

const AboutUs = (props: RouteComponentProps) => {
  const rows = new Array(6).fill(1)
  const cols = new Array(10).fill(1)

  return (
    <Flex flexDirection="column" height="100vh" width="100vw" overflow="hidden" bg="black">
      {rows.map((_, r) => (
        <Flex>
          {cols.map((_, c) => (
            <Foto key={`${r}-${c}`} row={r} col={c} />
          ))}
        </Flex>
      ))}
    </Flex>
  )
}

export default AboutUs
