import { ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import { render } from 'react-dom'

import Root from 'components/Root'

const container = document.getElementById('root')

render(
  <React.StrictMode>
    <ColorModeScript />
    <Root />
  </React.StrictMode>,
  container
)
