import React, { useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

interface Props {
  children: React.ReactNode
}

type LangCode = 'es' | 'en' | 'fr'
type Messages = {
  [langkey in LangCode]: { [id: string]: string }
}
const messages: Messages = {
  en: {
    'menu.gifts': 'Gifts',
    'menu.rsvp': 'R.S.V.P',
    'menu.the_itinerary': 'The Itinerary',
    'menu.the_wedding': 'The Wedding',
    'menu.travel': 'Travel',
    'quickrsvp.any_food_restrictions': 'Comments',
    'quickrsvp.guest_name': 'Guest Name',
    'quickrsvp.guests_in_party': 'Guests in your party',
    'quickrsvp.add_contact_for_more_info': 'Add your phone or email to receive updates',
    'quickrsvp.needs_hotel': 'Would you like to reserve a room?',
    'quickrsvp.will_be_attending': 'Will be attending',
    'main.we_are_getting_married': 'We are getting married!',
    'footer.made_with_love': 'Made with ',
    'footer.by_aj': 'by Alejandra and Jorge Adolfo',
    sending: 'Sending...',
    sent: 'Sent',
    error: 'Failed to send request',
    submit: 'Send',
    thanks: 'Thanks',
    weddingIs: 'The wedding was ',
    openWith: 'Open with ',
    close: 'Close',
    no: 'No',
    yes: 'Yes',
  },
  fr: {
    'menu.gifts': 'Cadeaux',
    'menu.rsvp': 'RSVP',
    'menu.the_itinerary': "L'itinéraire",
    'menu.the_wedding': 'Les noces',
    'menu.travel': 'Voyage',
    'quickrsvp.any_food_restrictions': 'Des commentaires ?',
    'quickrsvp.guest_name': "Nom de l'invité",
    'quickrsvp.guests_in_party': 'Des invités dans votre groupe ?',
    'quickrsvp.add_contact_for_more_info':
      'Ajoutez votre téléphone ou votre email pour recevoir des mises à jour',
    'quickrsvp.needs_hotel': 'Vous souhaitez réserver une chambre ?',
    'quickrsvp.will_be_attending': "J'assisterai",
    'main.we_are_getting_married': 'On va se marier!',
    'footer.made_with_love': 'Fait avec ',
    'footer.by_aj': 'par Alejandra & Jorge Adolfo',
    sending: 'Sending...',
    sent: 'Sent',
    error: 'Failed to send request',
    submit: 'Submit',
    thanks: 'Merci',
    weddingIs: 'Le mariage est ',
    openWith: 'Ouvrir avec ',
    close: 'Fermer',
    no: 'Non',
    yes: 'Ouais',
  },
  es: {
    'menu.gifts': 'Regalos',
    'menu.rsvp': 'RSVP',
    'menu.the_itinerary': 'El itinerario',
    'menu.the_wedding': 'La Boda',
    'menu.travel': 'Viaje',
    'quickrsvp.any_food_restrictions': '¿Algún comentario?',
    'quickrsvp.guest_name': 'Nombre Completo',
    'quickrsvp.guests_in_party': '¿Invitados en tu grupo? (Confirma por las demas personas en tu grupo)',
    'quickrsvp.add_contact_for_more_info': 'Agrega tu telefono o email para recibir más información',
    'quickrsvp.needs_hotel': '¿Te gustaría reservar una habitacion?',
    'quickrsvp.will_be_attending': 'Asistiré',
    'main.we_are_getting_married': '¡Nos vamos a casar!',
    'footer.made_with_love': 'Hecho con mucho ',
    'footer.by_aj': 'por Alejandra & Jorge Adolfo',
    sending: 'Enviando...',
    sent: 'Enviado',
    error: 'Hubo un fallo con el servidor',
    submit: 'Enviar',
    thanks: 'Gracias',
    weddingIs: 'La boda fue ',
    openWith: 'Abrir con ',
    close: 'Cerrar',
    no: 'No',
    yes: 'Si',
  },
}

const langCodes: Array<LangCode> = ['es', 'en', 'fr']

type LanguageContextType = {
  lang: LangCode
  setLang: (code: LangCode) => void
}
const initialContext: LanguageContextType = { lang: 'en', setLang: (code: LangCode) => {} }
const LanguageContext = React.createContext<LanguageContextType>(initialContext)

export const useLang = () => {
  return useContext(LanguageContext)
}

const LanguageProvider = ({ children }: Props) => {
  const code = navigator.language.split('-')[0] as LangCode
  const [lang, setLang] = useState<LangCode>(langCodes.includes(code) ? code : 'en')
  const localMessages = messages[lang]

  const value: LanguageContextType = {
    lang,
    setLang,
  }

  return (
    <IntlProvider messages={localMessages} locale={lang}>
      <LanguageContext.Provider value={value}>
        <>{children}</>
      </LanguageContext.Provider>
    </IntlProvider>
  )
}

export default LanguageProvider
