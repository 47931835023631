import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { useEffect } from 'react'

export const useCustomEffect = () => {
  const { isOpen: isVisible, onOpen: triggerVisible, onClose: tiggerNotVisible } = useDisclosure()
  const { isOpen: isWhite, onOpen: triggerWhite } = useDisclosure()
  const navigate = useNavigate()
  const isActive = true

  useEffect(() => {
    const meta = document.querySelector('meta[name="theme-color"]')
    // @ts-ignore
    meta.content = '#31302c'
  }, [])

  useEffect(() => {
    const id = setTimeout(() => {
      triggerVisible()
    }, 1000)

    return () => clearTimeout(id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const id = setTimeout(() => {
      tiggerNotVisible()
      triggerWhite()
    }, 5000)

    return () => clearTimeout(id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const id = setTimeout(() => {
      if (isActive) navigate('/wedding', { replace: true })
    }, 8100)

    return () => clearTimeout(id)
    // eslint-disable-next-line
  }, [])

  return { isVisible, isWhite }
}
