import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  GoogleAuthProvider,
} from 'firebase/auth'
import React, { useState, useContext, createContext, useEffect } from 'react'

import { auth } from 'config/firebase'

interface AuthContextProps {
  initialLoading: boolean
  currentUser: User | null
  signup: (email: string, password: string) => void
  login: (email: string, password: string) => void
  logout: () => void
  loginWithGoogle: () => void
  loginWithSmsSendCode: (phone: string) => Promise<void>
  loginWithSmsCheckCode: (code: string) => Promise<void>
}

const initialContext: AuthContextProps = {
  initialLoading: false,
  currentUser: null,
  signup: () => {},
  login: () => {},
  logout: () => {},
  loginWithGoogle: () => {},
  loginWithSmsSendCode: () => Promise.resolve(),
  loginWithSmsCheckCode: () => Promise.resolve(),
}
const AuthContext = createContext<AuthContextProps>(initialContext)

export const useAuth = () => {
  return useContext(AuthContext)
}

interface Props {
  children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const signup = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const loginWithSmsSendCode = async (phone: string) => {
    const cb = async () => {
      // @ts-ignore
      window.confirmationResult = await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
    }

    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response: unknown) => {
          console.log({ response })
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          cb()
        },
      },
      auth
    )
  }

  const loginWithSmsCheckCode = async (code: string) => {
    // @ts-ignore
    await window.confirmationResult.confirm(code)
  }

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider()

    return await signInWithPopup(auth, provider)
  }

  const logout = () => {
    return signOut(auth)
  }

  useEffect(() => {
    if (!currentUser) return
    // eslint-disable-next-line
  }, [currentUser])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value: AuthContextProps = {
    initialLoading: loading,
    currentUser,
    signup,
    login,
    logout,
    loginWithGoogle,
    loginWithSmsSendCode,
    loginWithSmsCheckCode,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
