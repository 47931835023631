import { Flex, Text, Image } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { AnimatePresence, motion } from 'framer-motion'
import { useCustomEffect } from 'hooks/utils'

const logoUrl = process.env.PUBLIC_URL + '/img/logo-white.png'

interface Props extends RouteComponentProps {
  fecha: string
}

const Home = ({ fecha }: Props) => {
  const { isWhite, isVisible } = useCustomEffect()

  return (
    <Flex
      height="100vh"
      width="100vw"
      alignItems="center"
      paddingTop="100px"
      bg="#31302c"
      flexDir="column"
      color="white"
      transition="1s"
    >
      <AnimatePresence>
        {!isWhite && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 4 }}
          >
            <Image boxSize="300px" objectFit="scale-down" src={logoUrl} alt="logo" />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <Flex flexDir="column" alignItems="center">
              <Flex fontSize="70px">
                <Text fontFamily="GalleryModern">save</Text>
                <Text fontFamily="Santa-Fe-Spring">-</Text>
                <Text fontFamily="Santa-Fe-Spring">our</Text>
                <Text fontFamily="Santa-Fe-Spring">-</Text>
                <Text fontFamily="GalleryModern">date</Text>
              </Flex>
              <Flex fontSize="50px">
                <Text fontFamily="GalleryModern" letterSpacing="5px">
                  {fecha}
                </Text>
              </Flex>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}

export default Home
