import { Button, Container, Flex } from '@chakra-ui/react'
import { Link } from '@reach/router'

import { useLang } from 'components/LanguageProvider'

interface Props {}

const LanguageTopbar = (props: Props) => {
  const { setLang } = useLang()

  const meta = document.querySelector('meta[name="theme-color"]')
  // @ts-ignore
  meta.content = '#31302c'
  const color = '#8f8d8d'

  return (
    <Flex flexDir="column" bg="#31302c" mb={5}>
      <Container color={color} maxW="container.xl" justifyContent="flex-end" display="flex" gap={2}>
        <Button size="xs" variant="ghost" as={Link} to="/app">
          app
        </Button>
        <Button size="xs" variant="ghost" onClick={() => setLang('es')}>
          Español
        </Button>
        <Button size="xs" variant="ghost" onClick={() => setLang('en')}>
          English
        </Button>
        <Button size="xs" variant="ghost" onClick={() => setLang('fr')}>
          Français
        </Button>
      </Container>
    </Flex>
  )
}

export default LanguageTopbar
