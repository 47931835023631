import {
  Button,
  Checkbox,
  CircularProgress,
  Code,
  Container,
  Flex,
  Icon,
  Image,
  Select,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Link, RouteComponentProps } from '@reach/router'
import { AirtableRecord, Guest, useFetchGuests, useFetchMess, useFetchRsvp } from 'hooks/airtable'
import { queryClient } from 'hooks/reactquery'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'
import { useQuery } from 'react-query'

import { useAuth } from 'config/AuthProvider'
import { dbGetValue } from 'config/firebase'
import LoginPage from 'pages/Login'

interface Props extends RouteComponentProps {}

const logoUrl = process.env.PUBLIC_URL + '/img/logo-white.png'

const useFirebase = (id?: string) => {
  return useQuery('get-user-' + id, () => dbGetValue('/admin/' + id).then((d) => d.val()), {
    enabled: Boolean(id),
  })
}

const SecureLayout = (_: Props) => {
  const { currentUser, logout } = useAuth()
  const [isLoadingSms, setLoadingSms] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({})
  const [sentMessages, setSentMessages] = useState<{ [key: string]: { isLoading: boolean; sent: boolean } }>(
    {}
  )
  const toast = useToast()
  const { data, isLoading, isError } = useFetchRsvp()
  const guests = useFetchGuests()
  const messages = useFetchMess()
  const { data: isAllowed, isLoading: authLoading } = useFirebase(currentUser?.uid)

  useEffect(() => {
    if (isError) {
      toast({ title: 'Server Request Failed', status: 'error' })
    }
  }, [isError, toast])

  const invalidateCache = () => {
    queryClient.invalidateQueries(['fetch-rsvp'])
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (currentUser) invalidateCache()
    }, 10000)

    return () => clearInterval(id)
  }, [currentUser])

  if (!currentUser) return <LoginPage />

  if (authLoading)
    return (
      <Stack alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress isIndeterminate color="#565347" />
      </Stack>
    )

  if (!isAllowed)
    return (
      <Code width="100vw" color="red">
        you are not allowed <Button onClick={logout}>logout</Button>
      </Code>
    )

  const meta = document.querySelector('meta[name="theme-color"]')
  // @ts-ignore
  meta.content = '#565347'

  const records = data?.records.map((r) => ({
    ...r,
    createdAt: DateTime.fromISO(r.createdTime),
  }))

  records?.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())

  const attending = records?.filter((r) => r.fields['Attending'] === 'yes').length
  const rooms = records?.filter((r) => r.fields['Needs Hotel'] === 'yes').length
  const total = records?.length

  const byTable: { [id: string]: Array<AirtableRecord<Guest>> } = {}

  if (guests.data?.records?.length) {
    guests.data.records.forEach((guest) => {
      byTable[guest.fields.table] = byTable[guest.fields.table] || []
      byTable[guest.fields.table].push(guest)
    })
  }

  const onSendUpdate = (name: string, number: string) => async () => {
    const twilioUrl =
      'https://api.twilio.com/2010-04-01/Accounts/AC6f4844eda700a5aba14b81923be1c2ec/Messages.json'

    const message = text.split('#name').join(name).split('\\n').join('\n')
    const body = [
      'To=' + encodeURIComponent(number.split(' ').join('')),
      'MessagingServiceSid=MGd20c6f93759dd2498ebe7467f19c1b89',
      'Body=' + encodeURIComponent(message),
    ].join('&')

    const headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    headers.append(
      'Authorization',
      'Basic QUM2ZjQ4NDRlZGE3MDBhNWFiYTE0YjgxOTIzYmUxYzJlYzo1ZmNmYzViZjUzYmIwZTI2NTljZjVhZWYxYTFkMzNhYw=='
    )

    try {
      setSentMessages({ ...sentMessages, [name]: { isLoading: true, sent: false } })
      setLoadingSms(true)
      await fetch(twilioUrl, { method: 'POST', headers, body })
      toast({ title: 'sms was sent!', status: 'success' })
      setSentMessages({ ...sentMessages, [name]: { isLoading: false, sent: true } })
    } catch {
      toast({ title: 'Failed to send sms', status: 'error' })
      setSentMessages({ ...sentMessages, [name]: { isLoading: false, sent: false } })
    } finally {
      setLoadingSms(false)
    }
  }

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const id = event.target.value
    const [message] = messages.data?.records.filter((msg) => msg.fields.id === id) || []
    if (!message) {
      toast({ title: 'no message is selected', status: 'warning' })

      return
    }
    setText(message.fields.text)
  }

  return (
    <Flex flexDir="column" height="100vh" overflow="auto">
      <Flex bg="#565347" zIndex="2" color="white" boxShadow="md" width="100vw" height="48px">
        <Container maxW="container.lg" display="flex" justifyContent="center" py={2}>
          <Button _hover={{ bg: '#656259' }} variant="ghost" onClick={invalidateCache}>
            Refresh
          </Button>
          <Spacer />
          <Link to="/wedding">
            <Image height="30px" src={logoUrl} />
          </Link>
          <Spacer />
          <Button _hover={{ bg: '#656259' }} variant="ghost" onClick={logout}>
            logout
          </Button>
        </Container>
      </Flex>
      <Flex flexDir="column" flex="1" overflow="auto">
        <Container maxW="container.lg" display="flex" overflow="auto" flex="1" boxSizing="content-box" p={0}>
          <Tabs
            colorScheme="blue"
            size="sm"
            display="flex"
            flex="1"
            overflow="auto"
            flexDir="column"
            variant="unstyled"
          >
            <TabPanels flex="1" overflow="auto">
              <TabPanel>
                <Flex flexDir="column">
                  {!isLoading &&
                    records?.map((record) => (
                      <Flex key={record.id} flexDir="column" borderTop="1px solid #c6c3b5" py={4}>
                        <b style={{ color: '#655b3f', fontSize: '17px' }}>{record.fields['Full Name']}</b>

                        <i style={{ borderLeft: '5px solid #e6e6e6', paddingLeft: '10px' }}>
                          {record.fields['Comments']}
                        </i>
                        <i style={{ borderLeft: '5px solid #7ea3fa', paddingLeft: '10px' }}>
                          {record.fields['Other Guests']}
                        </i>
                        <i style={{ borderLeft: '5px solid #7efaad', paddingLeft: '10px' }}>
                          {record.fields['Email Or Phone']}
                        </i>
                        <Flex gap={4}>
                          <Checkbox isDisabled defaultChecked={record.fields['Attending'] === 'yes'}>
                            Going
                          </Checkbox>
                          <Checkbox isDisabled defaultChecked={record.fields['Needs Hotel'] === 'yes'}>
                            Hotel
                          </Checkbox>
                          <i>
                            <small style={{ fontWeight: 'normal', color: '#655b3f' }}>
                              {record.createdAt.toRelative()}
                            </small>
                          </i>
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Text fontSize="45px">
                  Attending: {attending} / {total}
                </Text>
                <Text fontSize="45px">
                  Rooms: {rooms} / {total}
                </Text>
              </TabPanel>
              <TabPanel px={20}>
                <ol>
                  {records?.map((r) => (
                    <li key={r.id}>{r.fields['Full Name']}</li>
                  ))}
                </ol>
              </TabPanel>
              <TabPanel>
                <Select onChange={onSelect}>
                  <option value="">--</option>
                  {messages?.data?.records.map((message) => (
                    <option key={message.fields.id}>{message.fields.id}</option>
                  ))}
                </Select>
                <pre
                  style={{
                    marginTop: '10px',
                    border: '1px solid grey',
                    overflow: 'auto',
                    overflowWrap: 'anywhere',
                  }}
                >
                  ({text.length + ')\n'}
                  {text}
                </pre>
                <Flex flexDir="column">
                  {Object.keys(byTable)
                    .sort()
                    .map((tableId) => (
                      <Flex key={tableId} flexDir="column" mt={4} boxShadow="md" bg="whitesmoke">
                        <Text
                          fontSize="lg"
                          cursor="pointer"
                          p={2}
                          alignItems="center"
                          display="flex"
                          onClick={() => setCollapse({ ...collapse, [tableId]: !collapse[tableId] })}
                        >
                          <Icon as={!collapse[tableId] ? FaChevronRight : FaChevronDown} mr={2} />
                          {tableId}
                        </Text>
                        {collapse[tableId] &&
                          byTable[tableId].map((guest) => {
                            return (
                              <Flex key={guest.id} borderBottom="1px solid #ccc" padding={2}>
                                <Flex pr={4}>
                                  {guest.fields.fullname} <br />
                                  {guest.fields.number}
                                </Flex>
                                <Spacer />
                                {guest.fields.number && (
                                  <Button
                                    disabled={isLoadingSms || sentMessages[guest.fields.nickname]?.sent}
                                    isLoading={sentMessages[guest.fields.nickname]?.isLoading}
                                    onClick={onSendUpdate(guest.fields.nickname, guest.fields.number)}
                                    size="sm"
                                  >
                                    {sentMessages[guest.fields.nickname]?.sent ? 'Sent' : 'Send Update'}
                                  </Button>
                                )}
                              </Flex>
                            )
                          })}
                      </Flex>
                    ))}
                </Flex>
              </TabPanel>
            </TabPanels>
            <TabList
              bg="white"
              display="flex"
              boxShadow="0px 25px 30px 10px #444"
              borderBottom="20px solid #fff"
            >
              <Tab p={3} w="25%" _selected={{ boxShadow: 'none', borderTop: '5px solid #565347' }}>
                RSVP
              </Tab>
              <Tab p={3} w="25%" _selected={{ boxShadow: 'none', borderTop: '5px solid #565347' }}>
                Stats
              </Tab>
              <Tab p={3} w="25%" _selected={{ boxShadow: 'none', borderTop: '5px solid #565347' }}>
                People
              </Tab>
              <Tab p={3} w="25%" _selected={{ boxShadow: 'none', borderTop: '5px solid #565347' }}>
                Notify
              </Tab>
            </TabList>
          </Tabs>

          {isLoading && (
            <Stack alignItems="center" justifyContent="center" height="50vh">
              <CircularProgress isIndeterminate color="#565347" />
            </Stack>
          )}
        </Container>
      </Flex>
    </Flex>
  )
}

export default SecureLayout
