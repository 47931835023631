import { useMutation, UseMutationOptions, useQuery } from 'react-query'
const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY

const airtableHost = `https://api.airtable.com/v0/${baseId}/`
const getDbUrl = (dbName: string) => airtableHost + dbName + `?api_key=${apiKey}`

const dbUrl = getDbUrl('civil_invitations')
const guestUrl = getDbUrl('invitados_oficial')
const messageUrl = getDbUrl('mensajes')

const request = (url: string) => async () => {
  const res = await fetch(url)

  return res.json()
}

const createRsvp = async (rsvp: CivilInvitation) => {
  const res = await fetch(dbUrl, {
    headers: { 'Content-type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ records: [{ fields: rsvp }] }),
  })

  const data = await res.json()

  if (res.status > 299) {
    throw new Error(res.statusText)
  }

  return data
}

export interface AirtableRecord<T> {
  id: string
  createdTime: string
  fields: T
}

interface AirtableResponse<T> {
  records: Array<AirtableRecord<T>>
}

export interface CivilInvitation {
  uuid: string
  Attending: string
  Comments: string
  'Full Name': string
  'Other Guests': string
  'Email Or Phone': string
  'Needs Hotel': string
}
export interface Guest {
  fullname: string
  nickname: string
  from: string
  table: string
  number: string
  email: string
}

export interface Message {
  id: string
  text: string
}

export const useCreateRsvp = (options?: UseMutationOptions<any, unknown, CivilInvitation, unknown>) =>
  useMutation(createRsvp, { mutationKey: 'create-rsvp', ...options })
export const useFetchRsvp = () => useQuery<AirtableResponse<CivilInvitation>>(['fetch-rsvp'], request(dbUrl))
export const useFetchGuests = () => useQuery<AirtableResponse<Guest>>(['fetch-guest-list'], request(guestUrl))
export const useFetchMess = () => useQuery<AirtableResponse<Message>>(['fetch-messages'], request(messageUrl))
